<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>处方订单审核</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <!-- 用户编号： -->
      <el-input
        placeholder="请输入用户编号"
        clearable
        prefix-icon="el-icon-search"
        v-model="searchcustid"
        style="width: 160px;margin-right: 10px;"
        @clear="clear"
        size="small"
      >
      </el-input>
      <el-date-picker
        v-model="date"
        type="daterange"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right:10px;width:250px;"
        size="small"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-left:10px" @click="query" size="small">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%; margin-top: 40px">
      <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
      <el-table-column prop="Receiver" label="患者姓名" width="120"></el-table-column>
      <el-table-column prop="Tel" label="电话号" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.Tel.substring(0, 3) + "****" + scope.row.Tel.substring(7) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Cust_ID" label="用户编号" width="120"></el-table-column>
      <el-table-column prop="Order_ID" label="订单编号" width="160"></el-table-column>
      <el-table-column prop="Order_Date" label="创建时间" width="180"></el-table-column>
      <el-table-column prop="amount_str" label="金额"></el-table-column>
      <el-table-column prop="Solution" label="明细" width="240"></el-table-column>
      <el-table-column prop="" label="地址" width="240">
        <template slot-scope="scope">
          <span>
            {{ scope.row.Province }}{{ scope.row.City }}{{ scope.row.Counties
            }}{{ scope.row.Adress }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="160">
        <template slot-scope="scope">
          <div class="opt doc">
            <el-button type="primary" size="mini" @click="changeorderstate(scope.row, 'sh')">审核</el-button>
            <el-button type="danger" size="mini" @click="changeorderstate(scope.row, 'dh')">打回</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [10], // 个数选择器（可修改）
      PageSize: 10, // 默认每页显示的条数（可修改）
      value: false,
      searchcustid: "",
      date: "",
    };
  },
  mounted() {
    this.getdata(1, "");
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage, "");
    },
    clear() {},
    query() {
      var str = "";
      if (this.searchcustid !== "") {
        str += "&cust_id=" + this.searchcustid;
      }
      if (this.date != null && this.date.length > 0) {
        //create_datetime1:订单创建日期
        //datetime1:支付日期
        str += "&create_datetime1=" + this.date[0] + "&create_datetime2=" + this.date[1];
      }
      this.getdata(1, str);
    },
    getdata(page, str) {
      // state
      this.axios
        .get("/order/get_orders?page=" + page + "&size=" + this.PageSize + "&state=1" + str)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    changeorderstate(row, type) {
      var _state = "",
        str = "",
        str1 = "";
      if (type == "sh") {
        _state = "2";
        str = "审核成功";
        str1 = "审核";
      } else {
        _state = "3";
        str = "打回成功";
        str1 = "打回";
      }
      this.$confirm("此操作将" + str1 + "该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/order/update_order_state",
              this.qs.stringify({ state: _state, order_id: row.Order_ID })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: str,
                  type: "success",
                });
                this.getdata(1, "");
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((res) => {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
</style>
